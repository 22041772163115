<template>
  <div class="page">
    <Navbar title="考核任务" type="SMP" :callback="back" />
    <div class="total">
      <van-row>
        <van-col span="24"
          ><van-circle
            :current-rate="currentRate"
            layer-color="#ebedf0"
            :color="gradientColor"
            text="100%"
          />
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24">考核任务完成度</van-col>
      </van-row>
    </div>
    <van-row>
      <van-col span="24" class="title"
        ><van-icon name="label-o" /> 任务明细</van-col
      >
    </van-row>
    <div class="task">
      <van-row class="item" v-for="task in taskList" :key="task.code">
        <van-col span="6" class="icon"
          ><van-icon name="notes-o" :size="40"
        /></van-col>
        <van-col span="18">
          <van-row>
            <van-col span="16" class="name">{{ task.name }}</van-col>
            <van-col span="8" class="btn"
              ><van-button
                icon="plus"
                :color="COLOR"
                plain
                size="mini"
                @click="taskHandle(task.key, task.complete, task.limit)"
                >完成</van-button
              ></van-col
            >
          </van-row>
          <van-row>
            <van-col span="16" class="progress"
              ><van-progress :color="COLOR" :percentage="50"
            /></van-col>
            <van-col span="8"
              >{{ task.complete }}/{{ task.limit }}(<span
                v-if="task.period === 'DAY'"
                >每天</span
              ><span v-if="task.period === 'WEK'">每周</span
              ><span v-if="task.period === 'MTH'">每月</span>)</van-col
            >
          </van-row></van-col
        >
      </van-row>
      <!-- <van-row class="item">
        <van-col span="6" class="icon"
          ><van-icon name="shop-o" :size="40"
        /></van-col>
        <van-col span="18">
          <van-row>
            <van-col span="16" class="name">微客注册邀请</van-col>
            <van-col span="8" class="btn"
              ><van-button
                icon="plus"
                :color="COLOR"
                plain
                size="mini"
                @click="sellerInviter()"
                >邀请</van-button
              ></van-col
            >
          </van-row>
          <van-row>
            <van-col span="20" class="progress"
              ><van-progress :color="COLOR" :percentage="50"
            /></van-col>
            <van-col span="4">15/15</van-col>
          </van-row></van-col
        >
      </van-row> -->
    </div>
    <Tabbar active="own" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../module/common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Share from '../../module/common/Share.vue'
import { Progress, Icon, Circle } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Tabbar: Tabbar,
    Share: Share,
    [Progress.name]: Progress,
    [Icon.name]: Icon,
    [Circle.name]: Circle
  },
  data () {
    return {
      currentRate: 100,
      rate: 100,
      taskList: [],
      gradientColor: {
        '0%': '#1bfbe8',
        '100%': '#00CC99'
      }
    }
  },
  mounted () {
    this.retrieveTaskList()
    this.$refs.share.default('seller')
  },
  methods: {
    async retrieveTaskList () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: seller }
      const { data: res } = await this.$http.post(this.UMS_URL + '/assess/task/retrieveTaskSellerList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.taskList = res.data
      }
    },
    taskHandle (key, complete, limit) {
      if (key === 'MSA_DAILY_SIGN') {
        if (complete === limit) {
          this.$dialog.alert({
            title: '提示信息',
            message: '任务已完成'
          })
        }
      } else if (key === 'MSA_SELLER_INVITER') {
        this.sellerInviter()
      } else if (key === 'MSA_TASK_SHARE') {
        this.taskShare()
      } else if (key === 'MSA_PERSON_INVITER') {
        this.personInviter()
      }
    },
    personInviter () {
      this.$router.push({ path: '/seller/person' })
    },
    async personTest () {
      var pd = { key: 'MSA_PERSON_INVITER', amount: 1, operator: '1', seller: '50', user: '166', description: '', attach: '{personCode:93}' }
      const { data: res } = await this.$http.post(this.UMS_URL + '/assess/task/taskComplete', this.$qs.stringify(pd))
      if (res.status === '200') {
        console.log(res.data)
      }
    },
    taskShare () {
      this.$router.push({ path: '/seller/home/train' })
    },
    sellerInviter () {
      this.$router.push({ path: '/seller/inviter' })
    },
    back () {
      this.$router.push({ path: '/seller/own' })
    }
  }
}
</script>
<style lang="less" scoped>
.total {
  margin-top: 10px;
}
.title {
  height: 25px;
  margin-top: 10px;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#fff));
}
.task {
  margin-bottom: 70px;
  .item {
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
    .icon {
      margin-top: 10px;
    }
    .name {
      text-align: left;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      font-weight: 600;
    }
    .btn {
      height: 30px;
      line-height: 30px;
    }
    .progress {
      padding: 5px 0px 0px 0px;
    }
  }
}
</style>
